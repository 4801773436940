import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { NotificationContext } from "providers/notificationProvider";

import PageDataHandler from "utils/PageDataHandler";

import { PageInfo, ToolTypeBE, ToolTypeFE } from "types/SharedType";

import SeoComponent from "components/common/seoComponent/SeoComponent";
import HeroBanner from "components/layout/heroBanner";
import Nav from "components/layout/nav";
import Footer from "components/layout/footer";

import Introduction from "components/layout/introduction";
import MainPoints from "components/layout/mainPoints";
import Expertise from "components/layout/expertise/Expertise";
import ImageAccordian from "components/layout/imageAccordian";
import ProjectManagementOversight from "./components/projectManagementOversight";
import ProjectManagementTools from "./components/projectManagementTools";
import ProjectManagementStrengths from "./components/projectManagementStrengths";
import ProjectManagementTestimonies from "./components/projectManagementTestimonies";
import ProjectManagementTailoredSolutions from "./components/projectManagementTailoredSolutions";

import ProjectManagementImg from "assets/img/projectManagementDiagram.jpg";
import { sanitizeString } from "utils/commonFunctions";

const handleToolsListData = (toolsListData: ToolTypeBE[]) => {
	return toolsListData?.map((tool) => {
		return {
			logo: {
				url: `${process.env.REACT_APP_STRAPI_URL}${tool?.attributes?.logo?.logo?.data?.attributes?.url}`,
				caption: tool?.attributes?.logo?.logo?.data?.attributes?.caption,
				alternativeText:
					tool?.attributes?.logo?.logo?.data?.attributes?.alternativeText,
			},
			name: tool?.attributes?.name,
		};
	});
};

const ProjectManagement = () => {
	const { i18n, t } = useTranslation("aboutUs");
	const { t: pmTranslation } = useTranslation("projectManagement");
	const { displayNotification } = useContext(NotificationContext);

	const [pageInfo, setPageInfo] = useState<PageInfo>();
	const [toolsList, setToolsList] = useState<ToolTypeFE[]>([]);
	const [paginatedList, setPaginatedList] = useState<ToolTypeFE[]>([]);
	const [canLoadMore, setCanLoadMore] = useState<boolean>(false);

	const pageDataHandler = PageDataHandler();

	const currentPage = useRef(0);

	const loadMore = () => {
		currentPage.current = currentPage.current + 1;
		handlePagination(currentPage.current);
	};

	const handlePagination = (pageNum = 0) => {
		const expectedNumOfCaseStudies = (currentPage.current + 1) * 7;
		const totalNumOfCaseStudies = toolsList?.length;

		if (expectedNumOfCaseStudies < totalNumOfCaseStudies) {
			setCanLoadMore(true);
		} else {
			setCanLoadMore(false);
		}

		if (toolsList) {
			const paginatedToolsList = toolsList?.slice(0, (pageNum + 1) * 7);
			setPaginatedList(paginatedToolsList);
		}
	};

	const getPage = async (pageName: string, locale: string) => {
		try {
			const pageInfo = await pageDataHandler.getPageInfo(pageName, locale);
			const toolsList = handleToolsListData(pageInfo?.pageData?.tools?.data);

			pageInfo && setPageInfo(pageInfo);
			toolsList && setToolsList(toolsList);
		} catch (error) {
			console.error(
				`[Error - API] Error handling Project Management data`,
				error
			);
			displayNotification(
				`Something Went Wrong handling Project Management Data, Please Try Again !`,
				"error"
			);
		}
	};

	useEffect(() => {
		getPage("project-management-page", i18n.language);
		// eslint-disable-next-line
	}, [i18n.language]);

	useEffect(() => {
		currentPage.current = 0;
		handlePagination(0);
		// eslint-disable-next-line
	}, [toolsList]);

	const breadCrumb = `{
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": [
			{
				"@type": "ListItem",
				"position": 1,
				"item": {
						"@id": "${window.location.origin}",
						"name": "4i Tech: Home"
					}
			},
			{
				"@type": "ListItem",
				"position": 2,
				"item": {
						"@id": "${window.location.href}",
						"name": "4i Tech: Project Management"
					}
			}
		]
	}`;

	const pmSchema = `{
			"@context":"https://schema.org",
			"@type": "WebPage",
			"@id": "${window.location.href}",
			"url": "${window.location.href}",
			"name": "${pageInfo?.seo?.metaTitle}",
			"description": "${pageInfo?.seo?.metaDescription}",
			"inLanguage": "${i18n.language}",
			"about": {
				"@type": "Service",
				"name": "${sanitizeString(pageInfo?.detail?.title)}",
				"description": "${sanitizeString(pageInfo?.detail?.description)}",
				"provider": {
					"@type": "Organization",
					"name": "4i Tech",
					"url": "${window.location.origin}"
				}
			},
			"isPartOf":{
				"@type":"WebSite",
				"name":"4i Tech",
				"url":"${window.location.origin}"         
			}
		}`;

	return (
		<>
			<Nav />

			{pageInfo && (
				<SeoComponent
					metaTitle={pageInfo?.seo?.metaTitle}
					metaDescription={pageInfo?.seo?.metaDescription}
					metaImage={pageInfo?.seo?.metaImage}
					metaSocial={pageInfo?.seo?.metaSocial}
					canonicalURL={pageInfo?.seo?.canonicalURL}
					keywords={pageInfo?.seo?.keywords}
					metaRobots={pageInfo?.seo?.metaRobots}
					metaViewport={pageInfo?.seo?.metaViewport}
					structuredData={pageInfo?.seo?.structuredData}
					breadCrumb={breadCrumb}
					mainEntityOfPage={pmSchema}
				/>
			)}

			<HeroBanner
				picture={pageInfo?.detail?.bannerImage?.url}
				title={pageInfo?.detail?.title}
				description={pageInfo?.detail?.description}
			/>

			<Introduction title="introTitle" description="introDescription" />

			<MainPoints
				pointList={[
					{
						title: pmTranslation("projectManagementMainPointTitle1"),
						description: pmTranslation(
							"projectManagementMainPointDescription1"
						),
					},
					{
						title: pmTranslation("projectManagementMainPointTitle2"),
						description: pmTranslation(
							"projectManagementMainPointDescription2"
						),
					},
					{
						title: pmTranslation("projectManagementMainPointTitle3"),
						description: pmTranslation(
							"projectManagementMainPointDescription3"
						),
					},
				]}
			/>

			<ImageAccordian
				tag={t("projectManagementTag")}
				title={t("projectManagementTitle")}
				description={t("projectManagementDescription")}
				accordianList={[
					{
						title: t("projectManagementAccordionTitle1"),
						description: t("projectManagementAccordionDescription1"),
					},
					{
						title: t("projectManagementAccordionTitle2"),
						description: t("projectManagementAccordionDescription2"),
					},
					{
						title: t("projectManagementAccordionTitle3"),
						description: t("projectManagementAccordionDescription3"),
					},
					{
						title: t("projectManagementAccordionTitle4"),
						description: t("projectManagementAccordionDescription4"),
					},
					{
						title: t("projectManagementAccordionTitle5"),
						description: t("projectManagementAccordionDescription5"),
					},
					{
						title: t("projectManagementAccordionTitle6"),
						description: t("projectManagementAccordionDescription6"),
					},
				]}
				image={ProjectManagementImg}
				imageCaption={t("projectManagementPictureCaption")}
				buttonText={t("projectManagmentBtn")}
			/>

			<Expertise
				tag={pmTranslation("expertiseTag")}
				title={pmTranslation("expertiseTitle")}
				description={pmTranslation("expertiseDescription")}
				buttonText={pmTranslation("aboutUsBtn")}
			/>

			<ProjectManagementOversight />

			<ProjectManagementTools
				toolsList={paginatedList}
				canLoadMore={canLoadMore}
				loadMore={loadMore}
			/>

			<ProjectManagementStrengths />

			<ProjectManagementTestimonies />

			<ProjectManagementTailoredSolutions />

			<Footer />
		</>
	);
};

export default ProjectManagement;
